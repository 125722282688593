  <div nz-row class="back-kaizen" *ngIf="!noMostrar">
        <div nz-col [nzXs]="{span:0, offset:0}" [nzMd]="{ span: 2, offset: 1 }" [nzLg]="{ span: 2, offset: 1 }" class="rrss">
            Síguenos en:
            <div>
                <a href="https://www.facebook.com/kaizen.motors" target="_blank"><img [src]="imagefacebook" width="30" height="30" alt="facebook" class="rrss-icon"/></a>
                <a href="https://www.youtube.com/channel/UCfQFJCwSl3iMgq4AWN5a1lg" target="_blank"><img [src]="imageyoutube" width="30" height="30" alt="youtube" class="rrss-icon"/></a>
                <a href="https://www.linkedin.com/company/kaizen-motors/" target="_blank"><img [src]="imagelinkedin" width="30" height="30" alt="linkedin" class="rrss-icon"/></a>
            </div>
        </div>
        <div nz-col [nzXs]="{span:0, offset:0}" [nzMd]="{ span: 20, offset: 0 }" [nzLg]="{ span: 20, offset: 0 }">
            <ul nz-menu [nzMode]="'horizontal'" class="menu-foot">
                <li nz-menu-item><a routerLink = "/condiciones-de-uso">Condiciones de uso</a></li>
                <li nz-menu-item><a routerLink = "/politicas-de-privacidad">Políticas de privacidad</a></li>
                <li nz-menu-item>Anuncios </li>
            </ul>
            <div class="copyright">
                © 2022, Kaizen Motors - Bolivia
            </div>
        </div>
    </div>
    
    <div nz-col [nzXs]="{span:24, offset:0}" [nzMd]="{ span: 0, offset: 0 }" [nzLg]="{ span: 0, offset: 0 }" class="mobile-foot back-kaizen" *ngIf="!noMostrar">
        <ul nz-menu [nzMode]="'vertical'" class="menu-foot">
            <li nz-menu-item><a routerLink = "/condiciones-de-uso">Condiciones de uso</a></li>
            <li nz-menu-item><a routerLink = "/politicas-de-privacidad">Políticas de privacidad</a></li>
            <li nz-menu-item>Anuncios </li>
        </ul>
        <div class="rrss">
            Síguenos en:
            <div>
                <a><img [src]="imagefacebook" width="30" height="30" class="rrss-icon"/></a>
                <a><img [src]="imageyoutube" width="30" height="30" class="rrss-icon"/></a>
                <a><img [src]="imagelinkedin" width="30" height="30" class="rrss-icon"/></a>
            </div>
        </div>
        <div class="copyright">
            © 2025, Kaizen Motors - Bolivia
        </div>
    </div>

