import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { UserService } from '../services/core/user.service';
import { first } from 'rxjs/operators';
import { ProductosfiltrosService } from '../services/ecommerce/productosfiltros.service';
import { AuthService } from '../services/core/auth.service';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ShoppingCartService } from '../services/ecommerce/shopping-cart.service';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.css']
})
export class MenuHeaderComponent implements OnInit, OnDestroy {
  logo = 'https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1738616704/production/plataforma-kaizen/web/assets/logo_kaizen-20-anios.webp';
  visibleSubmenu;
  visibleSubmenuFiltros;
  visibleSubmenuTaller: any;
  icono: string ="";
  inputValue: string="";
  submenu: boolean = false;
  submenuFiltros: boolean= false;
  submenuTaller: boolean= false;
  logueado: boolean= false;

  marcas = [];

  nombreUsuario = "KAIZEN MOTORS"; 
  public userData: any;
  public userJson:any;
  searchDesktop: any;

  cantidadCart = 0;
  
  noMostrar=false;
  estadoSuscription: Subscription | undefined;

  constructor(private titleService: Title, private meta: Meta, public userService: UserService,
    private filtrosProductosService: ProductosfiltrosService, public authService: AuthService,
    private carro: ShoppingCartService, private router: Router, private dataService: DataService) {
    this.visibleSubmenu = false;
    this.visibleSubmenuFiltros = false;
      
    /*this.filtrosProductosService.getMarcas().pipe(first()).subscribe( data => {
      this.marcas = Object.values(data);
    }, error => {
        console.log(error);
    });*/

    //this.inicializandoEsquema();
  }



  onChange(value: any): void {
    console.log(value);
  }

  onKey(e: { key: string; }) {
    let searchKaizen = {};
    if(this.searchDesktop.length > 2) {
      if(e.key == "Enter"){
          this.router.navigate(['/busqueda/'+this.searchDesktop]);
      }
    }
  }

  linkSearch() {
    let searchKaizen = {};

      this.router.navigate(['/busqueda/'+this.searchDesktop]);
  }

  toggleSubmenu() {
    if (this.visibleSubmenu) {
      this.icono = 'unfold';
      this.visibleSubmenu = false;
    } else {
      this.icono = 'fold';
      this.visibleSubmenu = true;
    }
  }
  closeMenu() {
    this.visibleSubmenu = false;
    this.submenu = false;
    this.submenuFiltros = false;
  }

  ngOnInit() {
    this.estadoSuscription = this.dataService.marcas$.subscribe(estado => {
      this.marcas = Object.values(estado);
    });
    
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        scrollTo(0, 0);
        //this.viewModelsBlock = false;
      }

      if (event instanceof NavigationEnd) {
        scrollTo(0, 0);
        this.inicializandoEsquema();
        const pathname = window.location.pathname;
        
        switch (pathname) {
              case '/login':
                this.noMostrar = true;
                break;
    
              case '/servicio-tecnico':
                this.noMostrar = false;
                this.logo = 'https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1738616704/production/plataforma-kaizen/web/assets/logo_kaizen-20-anios.webp';
                
                break
              case '/tienda-virtual':
                this.noMostrar = false;
                break;
              
              default:
                this.noMostrar = false;
                this.logo = 'https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1738616704/production/plataforma-kaizen/web/assets/logo_kaizen-20-anios.webp';
                break;
            }
      }

      if (event instanceof NavigationError) {
        scrollTo(0, 0);
        //this.viewModelsBlock = false;
      }
    });
    
  }

  ngOnDestroy(): void {
    this.estadoSuscription?.unsubscribe();
  }

  inicializandoEsquema() {
    this.userData = localStorage.getItem('userKaizen');
    this.userJson = JSON.parse(this.userData);

    if(this.userJson != null){
        this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
        this.logueado = true;
        this.obtenerItemsCart();
    } else {
      this.nombreUsuario = "KAIZEN MOTORS";
      this.logueado = false;
    }
  }

  submenuvisible() {
    if (this.submenu) {
      this.submenu = false;
    } else {
      this.submenu = true;
    }
  }

  submenuFiltrosVisible() {
    this.submenuTaller = false;
    
    if (this.submenuFiltros) {
      this.submenuFiltros = false;
    } else {
      this.submenuFiltros = true;
    }
  }
  submenuTallerVisible() {
    this.submenuFiltros = false;

    if (this.submenuTaller) {
      this.submenuTaller = false;
    } else {
      this.submenuTaller = true;
    }
  }

  optionUser(): void {    
    
  }

  save(value: any){
    
  }

  logout(){
    this.userData = localStorage.getItem('userKaizen');
    this.userJson = JSON.parse(this.userData);
    console.log("User: " + this.userJson);

    if(this.userJson != null){
      localStorage.removeItem('userKaizen');
      localStorage.removeItem('kaizenSocial');
      localStorage.removeItem('pago');
      localStorage.removeItem('efectivoKaizen');
      localStorage.removeItem('tarjeta');
      localStorage.removeItem('deliveryKaizen');
      localStorage.removeItem('codeRE');
      localStorage.clear();
      this.nombreUsuario = "KAIZEN MOTORS";
      this.logueado = false;
      this.router.navigate(['/login']);      
    } else {
      this.logueado = true;
    }
  }

  obtenerItemsCart() {
      this.carro.productosCarro().pipe(first()).subscribe(
        data => {
          if(data){
            this.cantidadCart = data.detalle_productos.length;
          }
        }, error => {
          this.cantidadCart = 0;
          console.log(error.status); 
          setTimeout(() => {
          }, 2000);
      });
  }

  getUrl(nombre: string) {
    return "/repuesto/"+nombre.toLowerCase();
  }
}
